import BigNumber from 'bignumber.js'

import { getNetworkConfig } from '../../utils'

export type Options = { id: number; value: number }[]

export const tooltipText =
  'Slippage is a difference between the quoted price and the executed price of your trade.'

export const slippageAdjustedMessage =
  "This token's smart contract taxes each transaction. We adjusted slippage settings for you automatically"

export const highSlippageSettingsMessage =
  'You can execute trades with high slippage only if you allow it in DexGuru Settings ⚙️'

export const liquidityDropMessage =
  'You are about to lose more than 10% in dollar value due to low liquidity.'

export const options: Options = [
  { id: 0, value: 0.5 },
  { id: 1, value: 1 },
  { id: 2, value: 3 },
]

export const isPoop = (gasCosts: BigNumber, fromAmount: BigNumber): boolean =>
  (100 * (Number(gasCosts?.toFixed()) || 0)) / Number(fromAmount?.toFixed() || 0) > 25

export const feeTitle = (tokenNetwork: string): string => {
  const networkConfig = getNetworkConfig(tokenNetwork)
  return `${networkConfig.description} Network Fee`
}
